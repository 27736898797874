import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { PieWidget, CategoryWidget } from '@carto/react-widgets';
import { AggregationTypes } from '@carto/react-core';
import {
    addLayer,
    removeLayer,
    addSource,
    removeSource,
    setViewState,
  } from '@carto/react-redux';
import { setError } from '../../../store/app-slice';
import createMarketShareSource, { MARKET_SHARE_SOURCE_ID}  from '../../../sources/market-share-source';
import { MAP_LAYER_ID } from '../../layers/market-share-layer';
import { LayerSelect } from '../../common/layer-select';
import { brandPalette, createExpandedPalette, brandPurple } from '../../../../../utils/colors';
import { DataDisclaimer } from '../../common/data-disclaimer.component';
import { createWidgetActions }  from '../../../../../utils/utils';
import './maps.scss';


function MarketShare() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { subtab } = location.state;
    const centreStatus = useSelector((state) => state.marketShare.centreStatus);
    const viewState = useSelector((state) => state.carto.viewState);

    useEffect(() => {
        const mapSource = createMarketShareSource(subtab, centreStatus);
        dispatch(addSource(mapSource));
        dispatch(addLayer({ id: MAP_LAYER_ID, source: mapSource.id }));

        if(viewState.latitude === 0 && viewState.longitude === 0 && viewState.zoom === 0) {
            const latitude = subtab?.setup?.latitude || 0;
            const longitude = subtab?.setup?.longitude || 0;
            const zoom = subtab?.setup?.zoom || 3;
            dispatch(setViewState({ latitude: latitude, longitude: longitude, zoom: zoom, transitionDuration: 500 }));
        }


        return () => {
            dispatch(removeLayer(MAP_LAYER_ID));
            dispatch(removeSource(mapSource.id));
        };
    }, [dispatch, subtab, centreStatus]);

    const onWidgetError = (error) => {
        dispatch(setError(`Error in widget: ${error.message}`));
    };

    const expandedPalette = createExpandedPalette(brandPalette, 50);

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item sx={{
                    maxWidth:'420px !important',
                    '& section' : {
                        maxWidth:'100% !important',
                        width:'100% !important',
                        '& div' : {
                            maxWidth:'100% !important',
                        }
                    }
                }}>
                <PieWidget
                    id='operatorsCount'
                    title='Market share (centre count)*'
                    dataSource={MARKET_SHARE_SOURCE_ID}
                    column='operator_name'
                    onError={onWidgetError}
                    operation={AggregationTypes.COUNT}
                    colors={expandedPalette}
                    wrapperProps={{
                        actions:createWidgetActions('This pie chart represents the market share by operator, expressed as a percentage based on the center count.'),
                    }}
                    noDataAlertProps={{ title: 'No result available', body: "If you see pins on the map, it's because we protect our contributors' data by ensuring there are at least 4 centers, belonging to 3 different brands. Try zooming out to see results or selecting more brands." }}
                />
            </Grid>
            <Grid item className="brandFilterContainer" sx={{
                    maxWidth:'420px !important',
                    '& section' : {
                        maxWidth:'100% !important',
                        width:'100% !important',
                        '& div' : {
                            maxWidth:'100% !important',
                        }
                    }
                }}>
                <CategoryWidget
                    id='operatorsList'
                    title='Brands (centre count)*'
                    dataSource={MARKET_SHARE_SOURCE_ID}
                    column='operator_name'
                    operation={AggregationTypes.COUNT}
                    colors={[brandPurple,'#FFAAEE']}
                    color={brandPurple}
                    wrapperProps={{
                        actions:createWidgetActions('This list presents the number of centers for each operator. You can activate this list to filter the results and display the centers for one or a group of operators. You can search for the operators not displayed by default and categorized as Others.'),
                    }}
                    noDataAlertProps={{ title: 'No result available', body: "If you see pins on the map, it's because we protect our contributors' data by ensuring there are at least 4 centers, belonging to 3 different brands. Try zooming out to see results or selecting more brands." }}
                />
            </Grid>
            <Grid item sx={{
                    maxWidth:'420px !important',
                    '& section' : {
                        maxWidth:'100% !important',
                        width:'100% !important',
                        '& div' : {
                            maxWidth:'100% !important',
                        }
                    }
                }}>
                <LayerSelect/>
            </Grid>

            <Grid item sx={{
                    maxWidth:'420px !important',
                    '& section' : {
                        maxWidth:'100% !important',
                        width:'100% !important',
                        '& div' : {
                            maxWidth:'100% !important',
                        }
                    }
                }}>
                <DataDisclaimer />
            </Grid>
        </Grid>
    );
}

export default MarketShare;
