import { MAP_TYPES } from '@deck.gl/carto';
import { generateWhereClauses, generateQuery } from '../query-utils';

export const MARKET_DEMAND_SOURCE_ID = 'marketDemandSource';

export const MARKET_DEMMAND_CARTO_ORIGIN = 'carto-dw-ac-l5aq60v6.shared_us.prd_tab2_market_demand_18062024'

export default function createMarketDemandSource(currentSubtab) {
    const baseQuery = ` SELECT * FROM ${MARKET_DEMMAND_CARTO_ORIGIN}`;

    const whereClauses = generateWhereClauses(
        currentSubtab.filters ?? [],
        currentSubtab.not_filters ?? [],
        [`enquiry_id IS NOT NULL`]
    );

    const finalQuery = generateQuery(whereClauses, baseQuery);


    return {
        id: MARKET_DEMAND_SOURCE_ID,
        type: MAP_TYPES.QUERY,
        connection: 'carto_dw',
        data: finalQuery,
        isDroppingFeatures: false
    };
}
