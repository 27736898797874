import { currencyFormatter, numberFormatter } from './formatter';

export const decimalFormatter = (value) => {
    return parseFloat(value).toFixed(2);
}

const FORMATTER_TYPES = Object.freeze({
    CURRENCY: 'currency',
    NUMBER: 'number',
    DECIMAL: 'decimal',
    RENT_PREMIUM: 'rent_premium',
});

const formatterFunctions = {
    [FORMATTER_TYPES.CURRENCY](value) {
        const formatted = currencyFormatter(value);
        return `${formatted.prefix}${formatted.value}`;
    },
    [FORMATTER_TYPES.NUMBER](value) {
        return numberFormatter(value);
    },
    [FORMATTER_TYPES.DECIMAL](value) {
        return decimalFormatter(value);
    },
    [FORMATTER_TYPES.RENT_PREMIUM](value) {
        return `${decimalFormatter(value)}x`;
    },
};

const DEFAULT_FORMATTER = {
    type: '',
    columns: [],
};

export default function htmlForFeature({
    title,
    feature,
    formatter = DEFAULT_FORMATTER,
    includeColumns = '*',
    showColumnName = true,
}) {
    if (!feature) {
        throw new Error(`htmlForFeature needs "info.object" information`);
    }

    const propertyNames = Object.keys(feature.properties);

    if (
        formatter?.type &&
        formatter?.columns &&
        !isFormatterValid(propertyNames, formatter)
    ) {
        return;
    }

    if (!includedColumnsAreValid(propertyNames, includeColumns)) {
        return;
    }

    let html = '';

    if (title) {
        html = `<h3 style="margin: 0"><strong>${title}</strong></h3>`;
    }

    if (includeColumns === '*') {
        for (const name of propertyNames) {
            html = generateInnerHtml(name, formatter, includeColumns, showColumnName, feature, html);
        }
    }else {
        for (const column of includeColumns) {
            const columnName = typeof column === 'object' ? column.originalName : column;
            html = generateInnerHtml(columnName, formatter, includeColumns, showColumnName, feature, html);
        }
    }


  return html;
}

function generateInnerHtml(columnName, formatter, includeColumns, showColumnName, feature, html)  {
    if (shouldIncludeColumn(columnName, includeColumns)) {
        if (formatter?.columns.includes(columnName)) {
            const formatterFunction = formatterFunctions[formatter.type];
            html = generateHtml(feature, columnName, includeColumns, showColumnName, html, formatterFunction);
        } else {
            html = generateHtml(feature, columnName, includeColumns, showColumnName, html);
        }
    }
    return html;
}

function shouldIncludeColumn(name, includeColumns) {
    if (includeColumns === '*') {
      return true;
    }

    return includeColumns.some(column => {
        if (typeof column === 'string') {
            return column === name;
        } else if (typeof column === 'object' && column.originalName) {
            return column.originalName === name;
        }
        return false;
    });
  }

function generateHtml(
    feature,
    propertyName,
    includeColumns,
    showColumnName,
    html,
    formatterFunction = (v) => v
) {
    const columnInfo = Array.isArray(includeColumns) ? includeColumns.find(c => typeof c === 'object' && c.originalName === propertyName) : undefined;
    const columnNameToShow = columnInfo ? columnInfo.alias : propertyName;

    return html.concat(
        `${showColumnName ? `<strong>${columnNameToShow}</strong>: ` : ''}${formatterFunction(
        feature.properties[propertyName]
        )}<br/>`
    );
}

function isFormatterValid(properties, formatter) {
    const supportedTypes = Object.values(FORMATTER_TYPES);

    if (!supportedTypes.includes(formatter.type)) {
        throw new Error(
            `"${formatter.type}" is not supported as formatter, use one of "${supportedTypes}"`
        );
    }

    if (!formatter.columns || formatter.columns.length === 0) return true;

    if (!isArrayOfStrings(formatter.columns)) {
        throw new Error(`"formatter.columns" property needs to be an array of strings`);
    }

    for (const column of formatter.columns) {
        if (!properties.includes(column)) {
            const available = properties.join(', ');
            throw new Error(
                `"formatted.columns" includes '${column}' but it was not found!. Available cols are [${available}]`
            );
        }
    }

    return true;
}

function includedColumnsAreValid(properties, includeColumns) {
    if (includeColumns === '*') {
        return true;
    }

    if (!Array.isArray(includeColumns)) {
        throw new Error(`"includeColumns" must be an array of strings or objects`);
    }

    for (const column of includeColumns) {
        const columnName = typeof column === 'object' ? column.originalName : column;
        if (!properties.includes(columnName)) {
            throw new Error(`Column "${columnName}" set in "includeColumns" should exist in the feature`);
        }
    }

    return true;
}

function isArrayOfStrings(value) {
    return Array.isArray(value) && value.length && value.every(String);
}
