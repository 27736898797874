import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BarWidget, CategoryWidget } from '@carto/react-widgets';
import { AggregationTypes } from '@carto/react-core';
import { Grid, Link, styled } from '@mui/material';
import { NoDataAlert } from '@carto/react-ui';
import {
  addLayer,
  removeLayer,
  addSource,
  removeSource,
  setViewState,
  removeFilter
} from '@carto/react-redux';
import createFlexPriceSource, { FLEX_PRICE_SOURCE_ID, FLEX_PRICE_CARTO_ORIGIN } from '../../../sources/flex-price-source';
import createFlexPriceNoDealsSource, { FLEX_PRICE_NO_DEALS_SOURCE_ID } from '../../../sources/flex-price-no-deals-source';
import { FLEX_PRICE_LAYER_ID } from '../../layers/flex-price-layer';
import { FLEX_PRICE_NO_DEALS_LAYER_ID } from '../../layers/flex-price-no-deals-layer';
import FlexPriceLineChart from '../../widgets/flex-price-line-chart.component';
import { fetchSQLData, generateParamsForQuery } from '../../../api';
import { arrayToString } from '../../../../../utils/utils';
import { generateWhereClauses, generateQuery } from '../../../query-utils';
import { WrapperWidgetUI } from '@carto/react-ui';
import { DataDisclaimer } from '../../common/data-disclaimer.component';
import { CurrencySelector } from '../../common/currency-selector.component';
import { MinimumOperatorsDisclaimer } from '../../common/minimum-operators-disclaimer.component';
import { createWidgetActions }  from '../../../../../utils/utils';
import './maps.scss';
import { brandPurple } from '../../../../../utils/colors';


export default function FlexPrice() {
    const dispatch = useDispatch();
    const location = useLocation();
    const mapBoundaries = useSelector((state) => state.carto.viewport);
    const viewState = useSelector((state) => state.carto.viewState);
    const filters = useSelector((state) => state.carto.dataSources?.flexPriceSource?.filters);
    const { subtab } = location.state;

    const [uniqueOperators, setUniqueOperators] = useState(0);
    const [centreNumber, setCenterNumber] = useState(0);


    const [currency, setCurrency] = React.useState(localStorage.getItem('selectedCurrency') || 'USD');

    useEffect(() => {
        const savedCurrency = localStorage.getItem('selectedCurrency') || 'USD';
        setCurrency(savedCurrency);
    }, []);

    const currencySymbols = {
        USD: 'US$',
        GBP: 'GBP£',
        EUR: 'EUR€'
    };

    const currencyFields = {
        USD: 'cost_pppm_usd',
        GBP: 'cost_pppm_gbp',
        EUR: 'cost_pppm_eur'
    }

    const currencySymbol = currencySymbols[currency] || 'US$';
    const currencyField = currencyFields[currency] || 'cost_pppm_usd';

    const handleCurrencyChange = (newCurrency) => {
        setCurrency(newCurrency);
    };


    const ClearFiltersButton = styled(Link)(({ theme }) => ({
        ...theme.typography.caption,
        cursor: 'pointer',
        alignSelf: 'flex-end',
        marginRight: '15px'
    }));

    const clearFilters = () => {
        dispatch(
            removeFilter({
              id: FLEX_PRICE_SOURCE_ID,
              column: 'brand_tier',
              owner: 'revenueByBrandTier'
            })
        );
        dispatch(
            removeFilter({
              id: FLEX_PRICE_SOURCE_ID,
              column: 'operator_name',
              owner: 'operatorsList'
            })
        );
    };

    const fetchDataProtection = async () => {
        if (!mapBoundaries) return;

        const wktPolygon = `POLYGON((${mapBoundaries[0]} ${mapBoundaries[1]}, ${mapBoundaries[2]} ${mapBoundaries[1]}, ${mapBoundaries[2]} ${mapBoundaries[3]}, ${mapBoundaries[0]} ${mapBoundaries[3]}, ${mapBoundaries[0]} ${mapBoundaries[1]}))`;

        var baseSelect = `
            SELECT
                parent_operator_name,
                centre_id_fk
            FROM ${FLEX_PRICE_CARTO_ORIGIN}
        `;

        const extraWhereClauses = [
            `ST_Within(geom, ST_GeogFromText('${wktPolygon}'))`,
            `deal_id IS NOT NULL`
        ];

        if (filters) {
            const operatorNameFilters = filters.operator_name;
            if (operatorNameFilters) {
                extraWhereClauses.push(`operator_name IN ${arrayToString(operatorNameFilters.in.values)}`);
            }
            const brandTierFilters = filters.brand_tier;
            if (brandTierFilters) {
                extraWhereClauses.push(`brand_tier IN ${arrayToString(brandTierFilters.in.values)}`);
            }
        }

        const whereClauses = generateWhereClauses(
            subtab.filters ?? [],
            subtab.not_filters ?? [],
            extraWhereClauses
        );

        const finalBaseSelect = generateQuery(whereClauses, baseSelect);

        const query = `
            WITH BoundaryFiltered AS (
                ${finalBaseSelect}
            ),
            OperatorCounts AS (
                SELECT
                    parent_operator_name,
                    COUNT(DISTINCT centre_id_fk) AS counter
                FROM BoundaryFiltered
                GROUP BY parent_operator_name
            ),
            TotalCounts AS (
                SELECT
                    COUNT(DISTINCT parent_operator_name) AS unique_operators,
                    SUM(counter) AS total_centres
                FROM OperatorCounts
            ),
            TotalCentres AS(
                SELECT
                    count(distinct centre_id_fk) as centre_number
                FROM BoundaryFiltered
            )
            SELECT
                unique_operators,
                centre_number
            FROM TotalCounts, TotalCentres;
        `;
        const params = generateParamsForQuery(query);

        try {
            const data = await fetchSQLData(params);
            setUniqueOperators(data.rows[0].unique_operators);
            setCenterNumber(data.rows[0].centre_number);
        } catch (error) {
            console.error('Error:', error);
        }

    };

    useEffect(() => {
        fetchDataProtection();
    }, [mapBoundaries, subtab, filters]);

    useEffect(() => {
        const flexPriceNoDealsSource = createFlexPriceNoDealsSource(subtab);
        dispatch(addSource(flexPriceNoDealsSource));
        dispatch(addLayer({ id: FLEX_PRICE_NO_DEALS_LAYER_ID, source: FLEX_PRICE_NO_DEALS_SOURCE_ID }));
        const flexPriceSource = createFlexPriceSource(subtab);
        dispatch(addSource(flexPriceSource));
        dispatch(addLayer({ id: FLEX_PRICE_LAYER_ID, source: FLEX_PRICE_SOURCE_ID }));

        if(viewState.latitude === 0 && viewState.longitude === 0 && viewState.zoom === 0) {
            const latitude = subtab?.setup?.latitude || 0;
            const longitude = subtab?.setup?.longitude || 0;
            const zoom = subtab?.setup?.zoom || 3;
            dispatch(setViewState({ latitude: latitude, longitude: longitude, zoom: zoom, transitionDuration: 500 }));
        }

        return () => {
            dispatch(removeLayer(FLEX_PRICE_LAYER_ID));
            dispatch(removeLayer(FLEX_PRICE_NO_DEALS_LAYER_ID));
            dispatch(removeSource(FLEX_PRICE_SOURCE_ID));
            dispatch(removeSource(FLEX_PRICE_NO_DEALS_SOURCE_ID));
        };
    }, [dispatch, subtab]);

    const barCustomFormatter = (value) => Number(value.toFixed());
    const categoryCustomFormatter = (value) => "";

    return (
        <Grid container direction='column' spacing={2}>
            {
                (uniqueOperators < 3 || centreNumber < 4)? (
                    <Grid item sx={{ maxWidth:'420px !important',
                    '& section' : {
                        maxWidth:'100% !important',
                        width:'100% !important',
                        '& div' : {
                            maxWidth:'100% !important',
                        }
                    }, marginTop: '20px', display: 'flex', flexDirection: 'column'}}>

                        <ClearFiltersButton type="link" block onClick={() => clearFilters()}>
                            Clear Filters
                        </ClearFiltersButton>
                        <NoDataAlert title='No result available' body="If you see pins on the map, it's because we protect our contributors' data by ensuring there are at least 4 centers, belonging to 3 different brands. Try zooming out to see results or selecting more brands." sx={{ padding: 2 }}/>
                    </Grid>
                ) : (
                    <>
                        <Grid item className="barWidgetContainer" sx={{
                            maxWidth:'420px !important',
                            '& section' : {
                                maxWidth:'100% !important',
                                width:'100% !important',
                                '& div' : {
                                    maxWidth:'100% !important',
                                }
                            }
                        }}>
                            {
                                <CurrencySelector onCurrencyChange={handleCurrencyChange}/>
                            }
                        </Grid>
                         <Grid item className="barWidgetContainer" sx={{
                            maxWidth:'420px !important',
                            '& section' : {
                                maxWidth:'100% !important',
                                width:'100% !important',
                                '& div' : {
                                    maxWidth:'100% !important',
                                }
                            }
                        }}>
                            {
                                <>
                                    <BarWidget
                                        id="revenueByBrandTier"
                                        title={`Price by tier (${currencySymbol})*`}
                                        dataSource={FLEX_PRICE_SOURCE_ID}
                                        column="brand_tier"
                                        operationColumn={currencyField}
                                        filterable={false}
                                        yAxisFormatter={barCustomFormatter}
                                        operation={AggregationTypes.AVG}
                                        wrapperProps={{
                                            actions:createWidgetActions(`The Price Tier analysis is provided as an average, starting from January 2022 up to the current month. It represents the average price per tier. To determine the tier to which a deal belongs, we use a range-based approach. For context, we utilize range indicators in USD: deals between $100 and $399 are categorized as 'Economical', deals between $400 and $599 are classified as 'Mid-range', and deals above $600 are considered High-End.`),
                                        }}
                                        noDataAlertProps={{ title: 'No result available', body: "If you see pins on the map, it's because we protect our contributors' data by ensuring there are at least 4 centers, belonging to 3 different brands. Try zooming out to see results or selecting more brands."}}
                                    />
                                </>
                            }
                        </Grid>
                        <Grid item className="brandFilterContainer brandFlexPrice" sx={{
                                maxWidth:'420px !important',
                                '& section' : {
                                    maxWidth:'100% !important',
                                    width:'100% !important',
                                    '& div' : {
                                        maxWidth:'100% !important',
                                    }
                                },
                            }}>
                            <CategoryWidget
                                id='operatorsList'
                                title='Brands we have prices for*'
                                dataSource={FLEX_PRICE_SOURCE_ID}
                                column='operator_name'
                                operation={AggregationTypes.COUNT}
                                formatter={categoryCustomFormatter}
                                colors={[brandPurple,'#FFAAEE']}
                                color={brandPurple}
                                wrapperProps={{
                                    actions:createWidgetActions('This list presents the number of deals we have price for each operator. You can activate this list to filter the results and display the locatons of the deals for one or a group of operators. You can search for the operators not displayed by default and categorized as Others. To show some results you have to filter for at least 4 operators.'),
                                    footer:<MinimumOperatorsDisclaimer />
                                }}
                                noDataAlertProps={{ title: 'No result available', body: "If you see pins on the map, it's because we protect our contributors' data by ensuring there are at least 4 centers, belonging to 3 different brands. Try zooming out to see results or selecting more brands." }}
                            />

                        </Grid>
                        <Grid item sx={{
                            maxWidth:'420px !important',
                            '& section' : {
                                maxWidth:'100% !important',
                                width:'100% !important',
                                '& div' : {
                                    maxWidth:'100% !important',
                                }
                            }
                        }}>
                            <WrapperWidgetUI
                                title={`Achieved Rate by Desk - Net Contract Value (${currencySymbol})*`}
                                actions={createWidgetActions(`This chart presents the historical evolution of the price for one desk within a private office. It is provided as an average of the newly signed contracts for each month. The achieved rate is based on the net contract value, the actual price paid by the tenant at the contract signature. Source: Instant and contributing operators deals.`)}
                            >
                                <FlexPriceLineChart subtab={subtab} currencyField={currencyField} />
                            </WrapperWidgetUI>
                        </Grid>
                    </>
                )
            }
            <Grid item>
                <DataDisclaimer />
            </Grid>
        </Grid>
    );
}
