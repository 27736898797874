import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { NoDataAlert } from '@carto/react-ui';
import { arrayToString } from '../../../../utils/utils';
import { fetchSQLData, generateParamsForQuery } from '../../api';
import { generateWhereClauses, generateQuery } from '../../query-utils';
import { FLEX_PRICE_CARTO_ORIGIN } from '../../sources/flex-price-source';
import { brandPurple } from '../../../../utils/colors';
import { DateXAxisLabel } from './date-x-axis-label.component';
import { NumberFormatYAxisLabel } from './number-format-y-axis-label.component';
import { FlexPriceTooltip } from './flex-price-tooltip.component';

export default function FlexPriceLineChart({ subtab, currencyField }) {
    const mapBoundaries = useSelector((state) => state.carto.viewport);
    const brandTierFilters = useSelector((state) => state.carto.dataSources?.flexPriceSource?.filters?.brand_tier);

    const [widgetData, setWidgetData] = useState([]);

    const fetchData = async () => {
        if (!mapBoundaries) return;

        const wktPolygon = `POLYGON((${mapBoundaries[0]} ${mapBoundaries[1]}, ${mapBoundaries[2]} ${mapBoundaries[1]}, ${mapBoundaries[2]} ${mapBoundaries[3]}, ${mapBoundaries[0]} ${mapBoundaries[3]}, ${mapBoundaries[0]} ${mapBoundaries[1]}))`;

        let baseSelect = `
            SELECT
                month_start_date,
                operator_name,
                AVG(${currencyField}) as avg_cost_pppm
            FROM ${FLEX_PRICE_CARTO_ORIGIN}
        `;

        const extraWhereClauses = [
            `ST_Within(geom, ST_GeogFromText('${wktPolygon}'))`,
            `deal_id IS NOT NULL`
        ];

        if (brandTierFilters) {
            extraWhereClauses.push(`brand_tier IN ${arrayToString(brandTierFilters.in.values)}`);
        }

        const whereClauses = generateWhereClauses(
            subtab.filters ?? [],
            subtab.not_filters ?? [],
            extraWhereClauses
        );

        const finalBaseSelect = generateQuery(whereClauses, baseSelect);

        let leftJoinBaseSelect = `
            SELECT
                month_start_date,
                COUNT(DISTINCT parent_operator_name) AS parent_count
            FROM ${FLEX_PRICE_CARTO_ORIGIN}
        `;

        const leftJoinExtraWhereClauses = [
            `ST_Within(geom, ST_GeogFromText('${wktPolygon}'))`,
            `deal_id IS NOT NULL`
        ];

        if (brandTierFilters) {
            leftJoinExtraWhereClauses.push(`brand_tier IN ${arrayToString(brandTierFilters.in.values)}`);
        }

        const leftJoinWhereClauses2 = generateWhereClauses(
            subtab.filters ?? [],
            subtab.not_filters ?? [],
            extraWhereClauses
        );

        const leftJoinFinalBaseSelect = generateQuery(leftJoinWhereClauses2, leftJoinBaseSelect);


        const query = `
            WITH flex_data AS (
                ${finalBaseSelect}
                GROUP BY month_start_date, operator_name
                ORDER BY month_start_date ASC
            ),
            roll_avg AS (SELECT
                month_start_date as monthDate,
                AVG(avg_cost_pppm) OVER (
                    ORDER BY month_start_date
                    ROWS BETWEEN 5 PRECEDING AND CURRENT ROW
                ) AS achievedRate
            FROM (
                SELECT flex_data.month_start_date, AVG(CASE WHEN parent_count <= 2 THEN avg_cost_pppm  * 1.2 ELSE avg_cost_pppm END) as avg_cost_pppm
                FROM flex_data
                LEFT JOIN (${leftJoinFinalBaseSelect} GROUP BY month_start_date) parents_check ON flex_data.month_start_date = parents_check.month_start_date
                GROUP BY month_start_date
            )
                ORDER BY monthDate
            )
            SELECT * FROM roll_avg
            WHERE monthDate >= "2022-01"
        `;

        const params = generateParamsForQuery(query);
        return fetchSQLData(params);
    };

    const transformDataForWidget = (data) => {
        return data.rows.map((item) => ({
            name: item.monthDate,
            value: item.achievedRate,
        }));
    };

    useEffect(() => {
        fetchData()
            .then((data) => {
                if (data) {
                    const transformedData = transformDataForWidget(data);
                    setWidgetData(transformedData);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setWidgetData([]);
            });
    }, [mapBoundaries, brandTierFilters, subtab, currencyField]);

    return (
        widgetData.length > 0 ? (
            <LineChart margin={{ bottom: 40, left: 0, right: 30, top: 10 }} width={340} height={250} data={widgetData}>
                <XAxis dataKey="name" tick={<DateXAxisLabel/>}  interval={3} />
                <YAxis tick={<NumberFormatYAxisLabel/>} />
                <Tooltip  content={<FlexPriceTooltip />} />
                <Line type="monotone" dataKey="value" stroke={brandPurple} activeDot={{ r: 8 }} />
            </LineChart>
        ) : (
            <NoDataAlert title='No result available' body="If you see pins on the map, it's because we protect our contributors' data by ensuring there are at least 4 centers, belonging to 3 different brands. Try zooming out to see results or selecting more brands." sx={{ padding: 2 }}/>
        )
    );
}
