import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import {
  addLayer,
  removeLayer,
  addSource,
  removeSource,
  setViewState,
} from '@carto/react-redux';
import { setError } from '../../../store/app-slice';
import createFlexArbitrageSource, { FLEX_ARBITRAGE_SOURCE_ID } from '../../../sources/flex-arbitrage-source';
import { FLEX_ARBITRAGE_LAYER_ID } from '../../layers/flex-arbitrage-layer';
import { DataDisclaimer } from '../../common/data-disclaimer.component';
import { FormulaWidget, HistogramWidget } from '@carto/react-widgets';
import { AggregationTypes } from '@carto/react-core';
import { createWidgetActions }  from '../../../../../utils/utils';
import { FlexArbitrageRatioSlider } from '../../common/flex-arbitrage-ratio-slider';
import './maps.scss';


export default function FlexArbitrage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const viewState = useSelector((state) => state.carto.viewState);
    const flexArbitrageRatio = useSelector((state) => state.flexArbitrage.flexArbitrageRatio);
    const { subtab } = location.state;


    useEffect(() => {
        const flexArbitrageSource = createFlexArbitrageSource(subtab, flexArbitrageRatio);
        dispatch(addSource(flexArbitrageSource));
        dispatch(addLayer({ id: FLEX_ARBITRAGE_LAYER_ID, source: FLEX_ARBITRAGE_SOURCE_ID }));

        if(viewState.latitude === 0 && viewState.longitude === 0 && viewState.zoom === 0) {
            const latitude = subtab?.setup?.latitude || 0;
            const longitude = subtab?.setup?.longitude || 0;
            const zoom = subtab?.setup?.zoom || 3;
            dispatch(setViewState({ latitude: latitude, longitude: longitude, zoom: zoom, transitionDuration: 500 }));
        }

        return () => {
            dispatch(removeLayer(FLEX_ARBITRAGE_LAYER_ID));
            dispatch(removeSource(FLEX_ARBITRAGE_SOURCE_ID));
        };
    }, [dispatch, subtab, flexArbitrageRatio]);


    const onWidgetError = (error) => {
        dispatch(setError(`Error in widget: ${error.message}`));
    };

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <FormulaWidget
                    id='flexArbitrageRatio'
                    title='Flex Arbitrage Ratio in the Area'
                    dataSource={FLEX_ARBITRAGE_SOURCE_ID}
                    column='rent_preium_sqf'
                    onError={onWidgetError}
                    operation={AggregationTypes.AVG}
                    formatter={(value) => value.toLocaleString('en-US', {maximumFractionDigits:2})}
                    wrapperProps={{
                        actions:createWidgetActions('The Flex Arbitrage Ratio is the ratio of the price charged by operators to tenants and what they pay to their landlord for their lease. It is an estimate of the margin made by operator expressed as a multiple. This chart shows the average Flex Arbitrage Ratio in the area. Compare the Flex Arbitrage Ratio of a specific property to this average to know if the property is overperforming the market.'),
                    }}
                    noDataAlertProps={{ title: 'No result available', body: "If you see pins on the map, it's because we protect our contributors' data by ensuring there are at least 4 centers, belonging to 3 different brands. Try zooming out to see results or selecting more brands." }}
                />
            </Grid>
            <Grid item>
                <FlexArbitrageRatioSlider />

            </Grid>
            <Grid item>
                <HistogramWidget
                    id='flexArbitrageRatioDistribution'
                    title='Flex Arbitrage Ratio Distribution'
                    dataSource={FLEX_ARBITRAGE_SOURCE_ID}
                    column='rent_preium_sqf'
                    onError={onWidgetError}
                    ticks={[0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7]}
                    xAxisFormatter={(value) => value.toLocaleString('en-US', {maximumFractionDigits:2})}
                    wrapperProps={{
                        actions:createWidgetActions('The Flex Arbitrage Ratio is the ratio of the price charged by operators to tenants and what they pay to their landlord for their lease. It is an estimate of the margin made by operator expressed as a multiple. This chart shows the distribution of the Flex Arbitrage Ratio by range (0 to 1, 1 to 2, etc). Use this distribution chart to filter the area for the zone you are comfortable with or identify outliers.'),
                    }}
                    noDataAlertProps={{ title: 'No result available', body: "If you see pins on the map, it's because we protect our contributors' data by ensuring there are at least 4 centers, belonging to 3 different brands. Try zooming out to see results or selecting more brands." }}
                />
            </Grid>
            <Grid item>
                <DataDisclaimer />
            </Grid>
        </Grid>
    );
}
