import { useLocation } from 'react-router-dom';
import { addSource } from '@carto/react-redux';
import { Grid, Typography, Stack, Box } from '@mui/material';
import {  useDispatch, useSelector } from 'react-redux';
import createMarketShareSource from '../../sources/market-share-source';
import { Switch } from 'antd';
import { addStatus, removeStatus } from '../../store/market-share-slice';
import { createWidgetActions }  from '../../../../utils/utils';
import { WrapperWidgetUI } from '@carto/react-ui';


export function LayerSelect() {
    const location = useLocation();
    const dispatch = useDispatch();

    const { subtab } = location.state;
    const centreStatus = useSelector((state) => state.marketShare.centreStatus);

    const toggleStatus = (status, active) => {
        if (active) {
            dispatch(addStatus(status));
        } else {
            dispatch(removeStatus(status));
        }
        const mapSource = createMarketShareSource(subtab, centreStatus);
        dispatch(addSource(mapSource));
    };

    return (

        <WrapperWidgetUI
            title="Centres"
            actions={createWidgetActions('By toggling the button on this widget, you can select which  centres state  you want to display on the screen. By default, all centers are displayed.')}
        >
            <Box sx={{
                    width: '100%',
                    marginTop: '20px',
                    paddingTop: 0,
                }} alignItems='center' >
                <Grid container direction='column' alignItems='center' >
                    <Box sx={{ width: '100%', padding: '4px'}}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                            >
                                <Typography variant='body2'>Open Centres</Typography>
                            </Grid>
                            <Switch
                                name='Open Centres'
                                checked={centreStatus.includes('OPEN')}
                                onChange={(e) => toggleStatus('OPEN', e)}
                            />
                        </Stack>
                    </Box>
                </Grid>
                <Grid container direction='row' alignItems='center'>
                    <Box sx={{ width: '100%', padding: '4px' }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                            >
                                <Typography variant='body2'>Recently closed (last 24 mo)</Typography>
                            </Grid>
                            <Switch
                                name='Recently closed (last 24 mo)'
                                checked={centreStatus.includes('CLOSED')}
                                onChange={(e) => toggleStatus('CLOSED', e)}
                            />
                        </Stack>
                    </Box>
                </Grid>
            </Box>
        </WrapperWidgetUI>
    );
}

export default LayerSelect;
