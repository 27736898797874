import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { NoDataAlert } from '@carto/react-ui';
import { fetchSQLData, generateParamsForQuery } from '../../api';
import { generateWhereClauses, generateQuery } from '../../query-utils';
import { MARKET_DEMMAND_CARTO_ORIGIN } from '../../sources/market-demand-source';
import { brandPurple } from '../../../../utils/colors';
import { DateXAxisLabel } from './date-x-axis-label.component';
import { NumberFormatYAxisLabel } from './number-format-y-axis-label.component';
import { MarkdetDemmandTooltip } from './market-demand-tooltip.component';

export default function MarketDemandLineChart({ subtab }) {
    var mapBoundaries = useSelector((state) => state.carto.viewport);

    const [widgetData, setWidgetData] = useState([]);

    const fetchData = async () => {
        if (!mapBoundaries) return;

        const wktPolygon = `POLYGON((${mapBoundaries[0]} ${mapBoundaries[1]}, ${mapBoundaries[2]} ${mapBoundaries[1]}, ${mapBoundaries[2]} ${mapBoundaries[3]}, ${mapBoundaries[0]} ${mapBoundaries[3]}, ${mapBoundaries[0]} ${mapBoundaries[1]}))`;

        let baseSelect = `
            SELECT
                enquiry_date_formatted AS month_date,
                SUM(desks_per_sqft) AS sum_desks_per_sqft
            FROM ${MARKET_DEMMAND_CARTO_ORIGIN}
        `;

        const whereClauses = generateWhereClauses(
            subtab.filters ?? [],
            subtab.not_filters ?? [],
            [
                `ST_Within(geom, ST_GeogFromText('${wktPolygon}'))`,
                `enquiry_id IS NOT NULL`
            ]
        );

        const finalBaseSelect = generateQuery(whereClauses, baseSelect);

        const query = `
            WITH MonthlySums AS (
                ${finalBaseSelect}
                GROUP BY enquiry_date_formatted
                ORDER BY enquiry_date_formatted ASC
            ),
            AverageSum AS (
                SELECT
                  month_date,
                  AVG(sum_desks_per_sqft) OVER (
                    ORDER BY month_date
                    ROWS BETWEEN 4 PRECEDING AND CURRENT ROW
                  ) AS rolling_avg
                FROM MonthlySums
              )
            SELECT
                month_date,
                FLOOR(rolling_avg) AS rolling_avg
            FROM AverageSum
            ORDER BY month_date ASC
        `;

        const params = generateParamsForQuery(query);
        return fetchSQLData(params);
    };

    const transformDataForWidget = (data) => {
        return data.rows.map((item) => ({
            name: item.month_date,
            value: item.rolling_avg,
        }));
    };

    useEffect(() => {
        fetchData()
            .then((data) => {
                if (data) {
                    const transformedData = transformDataForWidget(data);
                    setWidgetData(transformedData);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setWidgetData([]);
            });
    }, [mapBoundaries, subtab]);


    return (
        widgetData.length > 0 ? (
            <LineChart sx={{}} margin={{bottom:40,left: 10,right: 30,top: 10}} width={340} height={250} data={widgetData}>
                <XAxis dataKey="name" tick={<DateXAxisLabel/>}  interval={3}/>
                <YAxis tick={<NumberFormatYAxisLabel/>} />
                <Tooltip  content={<MarkdetDemmandTooltip />} />
                <Line type="monotone" dataKey="value" stroke={brandPurple} activeDot={{ r: 8 }} />
            </LineChart>
        ) : (
            <NoDataAlert title='No result available' body="If you see pins on the map, it's because we protect our contributors' data by ensuring there are at least 4 centers, belonging to 3 different brands. Try zooming out to see results or selecting more brands." sx={{ padding: 2 }}/>
        )
    );
}
