import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import htmlForFeature from '../../../../utils/htmlForFeature';
import { hexToRGB, flexArbitragePalette } from '../../../../utils/colors';
import { LEGEND_TYPES } from '@carto/react-ui';

export const FLEX_ARBITRAGE_LAYER_ID = 'flexArbitrageLayer';

export const CATEGORY_COLORS = {
    '0-1' : hexToRGB(flexArbitragePalette[0], 204),
    '1-2' : hexToRGB(flexArbitragePalette[1], 204),
    '2-3' : hexToRGB(flexArbitragePalette[2], 204),
    '3-4' : hexToRGB(flexArbitragePalette[3], 204),
    '4-5' : hexToRGB(flexArbitragePalette[4], 204),
    '5+'  : hexToRGB(flexArbitragePalette[5], 204),
};

const DATA = Object.entries(CATEGORY_COLORS).map((elem) => {
    return { color: elem[1], label: elem[0] };
});

const layerConfig = {
    title: 'Flex Arbitrage (x)',
    visible: true,
    switchable: false,
    legend: {
        collapsible: false,
        type: LEGEND_TYPES.CATEGORY,
        labels: DATA.map((data) => data.label),
        colors: DATA.map((data) => data.color),
    },
};

export default function FlexArbitrageLayer() {
    const dispatch = useDispatch();
    const { flexArbitrageLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, flexArbitrageLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    if (flexArbitrageLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: FLEX_ARBITRAGE_LAYER_ID,
            getFillColor: (object) => {
                const value = Math.floor(object.properties.rent_preium_sqf || 0).toFixed(0);
                if (value < 5) {
                    return hexToRGB(flexArbitragePalette[value], 204);
                }
                return hexToRGB(flexArbitragePalette[5], 204);
            },
            getLineColor: (object) => {
                const value = Math.floor(object.properties.rent_preium_sqf || 0).toFixed(0);
                if (value < 5) {
                    return hexToRGB(flexArbitragePalette[value], 204);
                }
                return hexToRGB(flexArbitragePalette[5], 204);
            },
            pointRadiusMinPixels: 8,
            lineWidthMinPixels: 0.2,
            pickable: true,
            loadOptions: {
                fetch: {
                    headers: {
                        'cache-control': 'no-cache, max-age=0'
                    }
                }
            },
            onDataLoad: (data) => {
                dispatch(
                    updateLayer({ id: FLEX_ARBITRAGE_LAYER_ID, layerAttributes: { ...layerConfig } })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            onDataError: (error) => { console.log(error) },
            onHover: (info) => {
                if (info?.object) {
                    info.object = {
                        html: htmlForFeature({
                            feature: info.object,
                            formatter: {
                                type: 'rent_premium',
                                columns: ['rent_preium_sqf'],
                            },
                            includeColumns: [
                                {'originalName':'operator_name', 'alias' : 'Operator Name'},
                                {'originalName':'parent_operator_name', 'alias' : 'Parent Operator Name'},
                                {'originalName':'city', 'alias' : 'City'},
                                {'originalName':'address', 'alias' : 'Address'},
                                {'originalName':'rent_preium_sqf', 'alias' : 'Rent Premium'},
                            ],
                         }),
                        style: {},
                    };
                }
            },
        });
    }
}
