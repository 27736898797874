import React from 'react';
import ReactDOMClient from 'react-dom/client';
import 'antd/dist/antd.less';
import App from './App';
import { setupInterceptors } from './api';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import config from './config';
import * as serviceWorker from './serviceWorker';
import { createCartoSlice } from '@carto/react-redux';
import { setDefaultCredentials } from '@deck.gl/carto';
import { initialState } from './modules/carto/store/initial-state-slice';
import configureAppStore from './modules/carto/store/store';
import marketShareReducer from './modules/carto/store/market-share-slice';
import flexArbitrageReducer from './modules/carto/store/flex-arbitrage-slice';


const store = configureAppStore();

setupInterceptors(store);

store.reducerManager.add('carto', createCartoSlice(initialState));
store.reducerManager.add('marketShare', marketShareReducer);
store.reducerManager.add('flexArbitrage', flexArbitrageReducer);

const root = ReactDOMClient.createRoot(document.getElementById('root'));

setDefaultCredentials({ ...initialState.credentials });

root.render(
    <Provider store={store}>
        <Auth0Provider
            domain={config.REACT_APP_AUTH0_DOMAIN}
            clientId={config.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={config.REACT_APP_AUTH0_CALLBACK_URL}
            audience={config.REACT_APP_AUTH0_AUDIENCE}
        >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Auth0Provider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
